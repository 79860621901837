body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.mactive, .menu:hover {
  color: #d9166f; /* Change this color as needed */
}

/* Webkit (Safari, Chrome) */
::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* background of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #d9166f; /* color of the thumb */
  border-radius: 10px; /* roundness of the thumb */
}
.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
  height: 54px;
  font-size: 16px;
}
.rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover {
  border-color: #d9166f !important;
}
.rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #d9166f !important;
}
.rs-input-group {
  border: 1px solid rgba(0,0,0,0.25) !important;
}
.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999;
}